.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-sidebar {
  max-width: min(240px, 100%);
  height: auto;
  pointer-events: none;
}

.App-logo-foerder-welt {
  max-width: 240px;
  height: auto;
  pointer-events: none;

  @media (max-width: 490px) {
    display: none;
  }
}

.App-logo-dz {
  max-width: min(240px, 100%);
  height: auto;
  pointer-events: none;

  @media (max-width: 700px) {
    display: none;
  }
}

.App-logo-psd {
  height: auto;
  pointer-events: none;

  @media (max-width: 700px) {
    display: none;
  }
}

.Status-icons {
  max-width: 120px;
  max-height: 100px;
  pointer-events: none;
}

.Splash-image {
  width: 100%;
  height: auto;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}
